import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';
import { useTranslation } from 'react-i18next';

export default function Header(props) {

    const { t, i18n } = useTranslation();
    function handleClick(lang){
        i18n.changeLanguage(lang);
    }


    return (
        <div>
            <section className="header border-bottom" >
                <div className="header-main">
                    <div className="container">
                        <div className="row">
                            <nav className={`${props.bg} navbar navbar-expand-lg w-100 `} id="header-navbar">
                                <a className="navbar-brand" href="/">
                                    <img src={`${props.logo}`} alt="Simon Bourassa" width="50%" height="50"></img>
                                </a>

                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item">
                                            <Link to="/" className="nav-link text-uppercase" data-toggle="none">{t('Header.Home')}</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/about" className="nav-link text-uppercase" data-toggle="none">{t('Header.About')}&nbsp;{t('Header.Us')}</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/gallery" className="nav-link text-uppercase" data-toggle="none">{t('Header.Gallery')}</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/videos" className="nav-link text-uppercase" data-toggle="none">{t('Header.Videos')}</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/signUp" className="nav-link text-uppercase" data-toggle="none">{t('Header.Sign')}&nbsp;{t('Header.Up')}</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link text-uppercase" data-toggle="none" onClick={() => handleClick('en')}>En</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link text-uppercase" data-toggle="none" onClick={() => handleClick('fr')}>Fr</Link>
                                        </li>
                                    </ul>
                                </div>

                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )

}