import React from 'react';
import './TermsOfUse.scss';
import { useTranslation } from 'react-i18next';
import logo from "../../Media/Simon_Signature.png";
// import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';

export default function TermsOfUse() {

    const { t } = useTranslation();

    return (
        <div className="touPage">
            <div>
                {/* <Link to="/" className="for-logo">
                    <img style={{width: "25%"}} src={logo} />
                </Link> */}

                <Header bg={"navbar-light"} logo={logo} />
            </div>

            <div className="touDiv">
                <span className="mb-4 mt-4" style={{ justifyContent: "center", display: "flex" }}>{t('TOU.para1')}</span>

                <p>{t('TOU.para2')}</p><br />
                <p>{t('TOU.para3')}</p><br />
                <p>{t('TOU.para4')}</p><br />

                <p>{t('TOU.para5')}</p><br />
                <span>{t('TOU.para5_1')}</span><br />
                <span>{t('TOU.para5_2')}</span><br />
                <span>{t('TOU.para5_3')}</span><br /><br />

                <p>{t('TOU.para6')}</p><br />
                <p>{t('TOU.para7')}</p><br />

                <p>{t('TOU.1')}</p><br />

                <p>{t('TOU.2')}</p><br />
                <span>{t('TOU.2_1')}</span><br />
                <span>{t('TOU.2_2')}</span><br />
                <span>{t('TOU.2_3')}</span><br />
                <span>{t('TOU.2_4')}</span><br />
                <span>{t('TOU.2_5')}</span><br />
                <span>{t('TOU.2_6')}</span><br />
                <span>{t('TOU.2_7')}</span><br />
                <span>{t('TOU.2_8')}</span><br />
                <span>{t('TOU.2_9')}</span><br />
                <span>{t('TOU.2_10')}</span><br />
                <span>{t('TOU.2_11')}</span><br />
                <span>{t('TOU.2_12')}</span><br />
                <span>{t('TOU.2_13')}</span><br />
                <span>{t('TOU.2_14')}</span><br />
                <span>{t('TOU.2_15')}</span><br />
                <span>{t('TOU.2_16')}</span><br /><br />
                <p>{t('TOU.2smallPara')}</p><br />

                <p>{t('TOU.3')}</p><br />
                <p>{t('TOU.3smallPara1')}</p><br />
                <p>{t('TOU.3smallPara2')}</p><br />

                <p>{t('TOU.4')}</p><br />
                <p>{t('TOU.4smallPara1')}</p><br />

                <p>{t('TOU.5')}</p><br />
                <p>{t('TOU.5smallPara1')}</p><br />

                <p>{t('TOU.6')}</p><br />
                <p>{t('TOU.6smallPara1')}</p><br />
                <p>{t('TOU.6smallPara2')}</p><br />
                <p>{t('TOU.6smallPara3')}</p><br />
                <p>{t('TOU.6smallPara4')}</p><br />
                <p>{t('TOU.6smallPara5')}</p><br />

                <p>{t('TOU.7')}</p><br />
                <p>{t('TOU.7smallPara1')}</p><br />

                <p>{t('TOU.8')}</p><br />

                <p>{t('TOU.9')}</p><br />
                <p>{t('TOU.9smallPara1')}</p><br />

                <p>{t('TOU.10')}</p><br />
                <p>{t('TOU.11')}</p><br />
                <p>{t('TOU.12')}</p><br />

                <p>{t('TOU.13')}</p><br />
                <p>{t('TOU.13smallPara1')}</p><br />
                <p>{t('TOU.13smallPara2')}</p><br />

                <p>{t('TOU.14')}</p><br />
                <p>{t('TOU.15')}</p><br />
                <p>{t('TOU.16')}</p><br />
                <p>{t('TOU.17')}</p><br />

            </div>

        </div>
    )

}