import React from 'react';
import Header from '../../components/Header/Header';
import logo from "../../Media/Simon_Signature.png";
import './SignUp.scss';
import { useTranslation } from 'react-i18next';

export default function SignUp() {

    const { t } = useTranslation();

    return (
        <div>
            <Header bg={"navbar-light"} logo={logo} />

            <div className="signUpPage container">

                <div className="signup-form">
                    <form>
                        <h2>{t('Header.Sign')}&nbsp;{t('Header.Up')}</h2>
                        <p>{t('SignUp.tagline')}</p>
                        <hr />
                        <div className="form-group">
                            <label>{t('SignUp.name')}</label>
                            <input type="text" className="form-control" name="username" required="required" />
                        </div>
                        <div className="form-group">
                            <label>{t('SignUp.email')}</label>
                            <input type="email" className="form-control" name="email" required="required" />
                        </div>
                        <div className="form-group">
                            <label>{t('SignUp.password')}</label>
                            <input type="password" className="form-control" name="password" required="required" />
                        </div>
                        <div className="form-group">
                            <label>{t('SignUp.confirmPassword')}</label>
                            <input type="password" className="form-control" name="confirm_password" required="required" />
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-block btn-lg">{t('Header.Sign')}&nbsp;{t('Header.Up')}</button>
                        </div>
                    </form>
                </div>

            </div>

        </div>
    )

}