import React from 'react';
import Header from '../../components/Header/Header';
import './About.scss';
import SB36 from "../../Media/SimonBourassa©2021_2.png";
import simon_pic1 from '../../Media/09legrandcyclequirecommence.png';
import { useTranslation } from 'react-i18next';
import logo from "../../Media/Simon_Signature.png";
import { Link } from 'react-router-dom';

export default function About() {

    const { t } = useTranslation();

    return (
        <div className="aboutPage">
            <Header bg={"navbar-light"} logo={logo}/>

            <div className="container mt-4">
                <div className="row">
                    <div className="col-xs-12 col-md-6"> 
                        <img src={SB36} className="img-responsive img-fluid" alt="simon's art pic" /> 
                        {/* <div className="for-title">    
                            <Link to="/gallery">{t('Gallery.boom')}</Link>
                        </div> */}
                    </div>

                    <div class="col-xs-12 col-md-6">
                        <div className="about-text">
                            <h2>Bio</h2>
                            <p>{t('About.Bio_Para_1')}</p>
                            <p>{t('About.Bio_Para_2')}</p>
                            <p>{t('About.Bio_Para_3')}</p>
                            <p>{t('About.Bio_Para_4')}</p>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className="container mt-4">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="about-text">
                            {/* <h2>Creation</h2> */}
                            <h3>{t('About.Creation_Title')}</h3>
                            <p>{t('About.Creation_Para_1')}</p>
                        </div>
                    </div>

                    <div className="col-xs-12 col-md-6"> 
                        <img src={simon_pic1} className="img-responsive img-fluid" alt="simon's art pic" /> 
                        <div className="for-title">    
                            <Link to="/gallery">{t('Gallery.cycle')}</Link>
                        </div>
                    </div>

                </div>
            </div>
 
        </div>
        
    )

}