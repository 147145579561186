import React from 'react';
import './Privacy.scss';
import { useTranslation } from 'react-i18next';
import logo from "../../Media/Simon_Signature.png";
// import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';

export default function Privacy() {

    const { t } = useTranslation();

    return (
        <div className="privacyPage">
            <div>
                {/* <Link to="/" className="for-logo">
                    <img style={{width: "25%"}} src={logo} />
                </Link> */}

                <Header bg={"navbar-light"} logo={logo} />
            </div>

            <div className="privacyDiv">
                <span className="mb-4 mt-4" style={{ justifyContent: "center", display: "flex" }}>{t('Privacy.title')}</span>

                <p>{t('Privacy.para1')}</p><br />
                <p>{t('Privacy.para2')}</p><br />
                <p>{t('Privacy.para3')}</p><br />
                <p>{t('Privacy.para4')}</p><br />

                <span>{t('Privacy.title1')}</span>
                <p>{t('Privacy.ans1')}</p>

                <span>{t('Privacy.title2')}</span>
                <p>{t('Privacy.ans2')}
                    <br /><span>{t('Privacy.a')}</span><br />
                    <span>{t('Privacy.b')}</span><br />
                    <span>{t('Privacy.c')}</span><br />
                    <span>{t('Privacy.d')}</span><br />
                    <span>{t('Privacy.e')}</span><br />
                    <span>{t('Privacy.f')}</span><br />
                    <span>{t('Privacy.g')}</span></p>

                <span>{t('Privacy.title3')}</span>
                <p>{t('Privacy.ans3')}</p>

                <span>{t('Privacy.title4')}</span>
                <p>{t('Privacy.ans4')}</p>
                <p>{t('Privacy.4')}</p>

                <span>{t('Privacy.title5')}</span>
                <p>{t('Privacy.ans5')}</p>

                <span>{t('Privacy.title6')}</span>
                <p>{t('Privacy.ans6')}</p>

                <span>{t('Privacy.title7')}</span>
                <p>{t('Privacy.ans7')}</p>

                <span>{t('Privacy.title8')}</span>
                <p>{t('Privacy.ans8')}</p>
                <p>{t('Privacy.8')}</p>

                <span>{t('Privacy.title9')}</span>
                <p>{t('Privacy.ans9')}</p>

                <span>{t('Privacy.title10')}</span>
                <p>{t('Privacy.ans10')}</p>

                <span>{t('Privacy.add1')}</span><br />
                <span>{t('Privacy.add2')}</span><br />
                <span>{t('Privacy.add3')}</span><br />
                <span>{t('Privacy.add4')}</span><br /><br />
                <span>{t('Privacy.mail')}</span><br /><br />
                <span>{t('Privacy.phone')}</span><br /><br />

                <span>{t('Privacy.title11')}</span>
                <p>{t('Privacy.ans11')}</p>

                <span>{t('Privacy.title12')}</span>
                <p>{t('Privacy.ans12')}</p>

            </div>

        </div>
    )

}