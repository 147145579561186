import React from 'react';
import Header from '../../components/Header/Header';
import './Contact.scss';
import mainentrance from '../../Media/01CarréVertSouriant.png';
import logo from "../../Media/Simon_Signature.png";
import monloveLogo from "../../Media/Logo-Purple3.png";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Contact() {

    const { t } = useTranslation();

    return (
        <div className="contactPage">
            <Header bg={"navbar-light"} logo={logo}/>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-xs-12 col-md-6"> 
                        <img src={mainentrance} className="img-responsive img-fluid" alt="mainentrance pic" /> 
                        <div className="for-title">    
                            <Link to="/gallery">{t('Gallery.green')}</Link>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-6">
                        <div className="for-add">
                            <img src={monloveLogo} className="img-responsive img-fluid mb-4 mt-4" alt="monlove Logo" />
                            <p>4315 Rue Frontenac #200</p>
                            <p>Montreal QC H2H 2M4</p><br/>
                            <p>+1 514 587 2992</p><br/>
                            <p>{t('Contact.email')}</p>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
        
    )

}