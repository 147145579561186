// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../Media/boom.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".homePage .homePageUpper {\n  height: 100vh;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center; }\n\n.homePage .videoPlayer {\n  height: 96vh;\n  max-height: 900px; }\n\n@media only screen and (max-width: 600px) {\n  .homePage .videoPlayer {\n    height: 280px; } }\n\n@media only screen and (min-width: 601px) and (max-width: 800px) {\n  .homePage .videoPlayer {\n    height: 400px; } }\n\n@media only screen and (min-width: 800px) and (max-width: 1024px) {\n  .homePage .videoPlayer {\n    height: 500px; } }\n", "",{"version":3,"sources":["webpack://src/Pages/Home/Home.scss"],"names":[],"mappings":"AAAA;EAGQ,aAAa;EACb,yDAA6C;EAC7C,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B,EAAA;;AAPnC;EAeQ,YAAY;EACZ,iBACJ,EAAA;;AAEA;EAnBJ;IAqBY,aAAa,EAAA,EAChB;;AAGL;EAzBJ;IA2BY,aAAa,EAAA,EAChB;;AAGL;EA/BJ;IAiCY,aAAa,EAAA,EAChB","sourcesContent":[".homePage{\n    .homePageUpper {\n        //width: 100%;\n        height: 100vh;\n        background-image: url(\"../../Media/boom.png\");\n        background-size: cover;\n        background-repeat: no-repeat;\n        background-position: center;\n    }\n\n    // .poster > div img {\n    //     display: none;\n    // }\n\n    .videoPlayer {\n        height: 96vh;\n        max-height: 900px\n    }\n    \n    @media only screen and (max-width: 600px) {\n        .videoPlayer {\n            height: 280px;\n        }\n    }\n    \n    @media only screen and (min-width: 601px) and (max-width: 800px) {\n        .videoPlayer {\n            height: 400px;\n        }\n    }\n    \n    @media only screen and (min-width: 800px) and (max-width: 1024px) {\n        .videoPlayer {\n            height: 500px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
