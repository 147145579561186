import React from 'react';
import Home from './Pages/Home/Home';
import Gallery from './Pages/Gallery/Gallery';
import About from './Pages/About/About';
import SignUp from './Pages/SignUp/SignUp';
import Videos from './Pages/Videos/Videos';
import Privacy from './Pages/Privacy/Privacy';
import TermsOfUse from './Pages/TermsOfUse/TermsOfUse';
import Contact from './Pages/Contact/Contact';
import Footer from './components/Footer/Footer';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';



function App() {
    return (
        <Router>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>

                    <Route path="/about">
                        <About />
                    </Route>

                    <Route path="/gallery">
                        <Gallery />
                    </Route>

                    <Route path="/videos">
                        <Videos />
                    </Route>

                    <Route path="/signUp">
                        <SignUp />
                    </Route>
                    <Route path="/privacy">
                        <Privacy />
                    </Route>
                    <Route path="/termsOfUse">
                        <TermsOfUse />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>
                </Switch>
                <Footer/>
        </Router>
    );
}

export default App;