import React from 'react';
import './Home.scss'
import Header from '../../components/Header/Header';
import logo from "../../Media/simon_white_transp.png";

export default function Home() {

    return (
        <div class="homePage">
            <div className="homePageUpper">
                <Header bg={"navbar-dark"} logo={logo} />
            </div>
        </div>
    )
}