import React from 'react';
import Header from '../../components/Header/Header';
import logo from "../../Media/Simon_Signature.png";
import { useTranslation } from 'react-i18next';

//square
import SB1 from "../../Media/01CarréVertSouriant.png";
import SB5 from "../../Media/05ledauphin.png";
import SB6 from "../../Media/06lebébé.png";
import SB7 from "../../Media/07lebiengrandissant.png";
import SB8 from "../../Media/08formestri-dimensionnelles.png";
import SB9 from "../../Media/09legrandcyclequirecommence.png";
import SB11 from "../../Media/11boom.png";
import SB13 from "../../Media/13lecoupleentourédecouleur.png";
import SB20 from "../../Media/20laflèchedor.png";
import SB21 from "../../Media/21lediamant.png";
import SB23 from "../../Media/23Lasagesse.png";
import SB24 from "../../Media/24magnificence.png";
import SB25 from "../../Media/25noiretblanc.png";
import SB26 from "../../Media/26lereveur.png";
import SB27 from "../../Media/27oeuvrepointilliste.png";

//rectangle
import SB4 from "../../Media/04LeZebre.png";
import SB12 from "../../Media/12lesbulles.png";
import SB15 from "../../Media/15lecycledelavie.png";
import SB16 from "../../Media/16lerêvealéatoire.png";
import SB17 from "../../Media/17ondespositives.png";
import SB28 from "../../Media/28tourbillionrosesurfondjaune.png";
import SB31 from "../../Media/Lejouretlanuit.png";
import SB32 from "../../Media/LeVaisseauspatial.png";
import SB33 from "../../Media/lesastres.png";
import SB34 from "../../Media/lesauroreboreales.png";
import SB35 from "../../Media/lesÉtoiles.png";

//vertical
import SB3 from "../../Media/03SouriresurfondVert.png";
import SB2 from "../../Media/02LeSeulChemin.png";
import SB10 from "../../Media/10legrandbonheur.png";
import SB14 from "../../Media/14l'au-delà.png";
import SB18 from "../../Media/18lesdeuxbonheursdelavie.png";
import SB19 from "../../Media/19souriresurfondorange.png";
import SB22 from "../../Media/22penséegrandissantesurfondmauve.png";
import SB29 from "../../Media/29l'évolution.png";
import SB30 from "../../Media/30Lerire.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export default function Gallery() {

    const { t } = useTranslation();

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobilefirst: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }]
    };

    // var settingsRec = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     mobilefirst: true,
    //     responsive: [
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 1
    //             }
    //         }]
    // };

    return (
        <div className="galleryPage">
            <Header bg={"navbar-light"} logo={logo} />


            <div className="container">
                <div className="gallerySlick mt-3">
                    <style>{cssstyle}</style>

                    <Slider {...settings} className="gallerySlider">
                        <div className="galleryDiv">
                            <img src={SB1} alt="simon's art"></img>
                            <h3>{t('Gallery.green')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>48"X48"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB2} alt="simon's art"></img>
                            <h3>{t('Gallery.way')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>48"X36"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB3} alt="simon's art"></img>
                            <h3>{t('Gallery.greenBG')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>48"X36"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB4} alt="simon's art"></img>
                            <h3>{t('Gallery.zebra')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>30"X40"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB5} alt="simon's art"></img>
                            <h3>{t('Gallery.dolphin')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>48"X48"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB6} alt="simon's art"></img>
                            <h3>{t('Gallery.baby')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>40"X40"</h6>
                        </div>                      
                        <div className="galleryDiv">
                            <img src={SB7} alt="simon's art"></img>
                            <h3>{t('Gallery.gg')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>40"X40"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB8} alt="simon's art"></img>
                            <h3>{t('Gallery.threeDimensional')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>40"X40"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB9} alt="simon's art"></img>
                            <h3>{t('Gallery.cycle')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>48"X48"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB10} alt="simon's art"></img>
                            <h3>{t('Gallery.happiness')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>40"X30"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB11} alt="simon's art"></img>
                            <h3>{t('Gallery.boom')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>40"X40"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB12} alt="simon's art"></img>
                            <h3>{t('Gallery.bubbles')}</h3>
                            <h6>{t('Gallery.Pastels')}</h6><br/>
                            <h6>25"X36"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB13} alt="simon's art"></img>
                            <h3>{t('Gallery.couple')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>48"X48"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB14} alt="simon's art"></img>
                            <h3>{t('Gallery.beyond')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>40"X30"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB15} alt="simon's art"></img>
                            <h3>{t('Gallery.COL')}</h3>
                            <h6>{t('Gallery.ink')}</h6><br/>
                            <h6>20"X26"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB16} alt="simon's art"></img>
                            <h3>{t('Gallery.random')}</h3>
                            <h6>{t('Gallery.ink')}</h6><br/>
                            <h6>18"X24"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB17} alt="simon's art"></img>
                            <h3>{t('Gallery.positive')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>36"X48"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB18} alt="simon's art"></img>
                            <h3>{t('Gallery.pleasure')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>48"X36"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB19} alt="simon's art"></img>
                            <h3>{t('Gallery.orangeBg')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>48"X36"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB20} alt="simon's art"></img>
                            <h3>{t('Gallery.goldenArrow')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>40"X40"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB21} alt="simon's art"></img>
                            <h3>{t('Gallery.dimond')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>40"X40"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB22} alt="simon's art"></img>
                            <h3>{t('Gallery.purpleBg')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>48"X36"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB23} alt="simon's art"></img>
                            <h3>{t('Gallery.wisdom')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>40"X40"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB24} alt="simon's art"></img>
                            <h3>{t('Gallery.magnificence')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>40"X40"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB25} alt="simon's art"></img>
                            <h3>{t('Gallery.W&B')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>36"X36"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB26} alt="simon's art"></img>
                            <h3>{t('Gallery.lucid')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>36"X36"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB27} alt="simon's art"></img>
                            <h3>{t('Gallery.pointillist')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>40"X40"</h6>
                        </div>                        
                        <div className="galleryDiv">
                            <img src={SB28} alt="simon's art"></img>
                            <h3>{t('Gallery.yellowBg')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>30"X40"</h6>
                        </div>                       
                        <div className="galleryDiv">
                            <img src={SB29} alt="simon's art"></img>
                            <h3>{t('Gallery.acrlylicPaper')}</h3>
                            <h6>{t('Gallery.Acrylic')}</h6><br/>
                            <h6>26"X20"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB30} alt="simon's art"></img>
                            <h3>{t('Gallery.laugh')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>48"X36"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB31} alt="simon's art"></img>
                            <h3>{t('Gallery.jour')}</h3>
                            <h6>{t('Gallery.Pastels')}</h6><br/>
                            <h6>25"X36"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB32} alt="simon's art"></img>
                            <h3>{t('Gallery.Vaisseau')}</h3>
                            <h6>{t('Gallery.canvas')}</h6><br/>
                            <h6>30"X40"</h6>
                        </div>
                        <div className="galleryDiv">
                            <img src={SB33} alt="simon's art"></img>
                            <h3>{t('Gallery.astres')}</h3>
                            <h6>{t('Gallery.graphic')}</h6><br/>
                            {/* <h6>48"X36"</h6> */}
                        </div>
                        <div className="galleryDiv">
                            <img src={SB34} alt="simon's art"></img>
                            <h3>{t('Gallery.boreales')}</h3>
                            <h6>{t('Gallery.graphic')}</h6><br/>
                            {/* <h6>48"X36"</h6> */}
                        </div>
                        <div className="galleryDiv">
                            <img src={SB35} alt="simon's art"></img>
                            <h3>{t('Gallery.etoiles')}</h3>
                            <h6>{t('Gallery.graphic')}</h6><br/>
                            {/* <h6>48"X36"</h6> */}
                        </div>
                    </Slider>
                </div>

            </div>
        </div>
    )

}

const cssstyle = `
.gallerySlick {
  margin: 0 auto;
  //padding: 0px 0px 40px 0px;
  width: 100%;
  box-sizing: border-box; 
}

.gallerySlider{
  overflow: hidden;
  width: 0;
  min-width: 100%;
}
.galleryDiv{
  overflow: hidden;
  position: relative;
}

.galleryDiv img{
   margin: 0 auto;
}

.galleryDiv h3,h6{
    text-align: center;
}

.galleryDiv h3{
    margin-top: 10px;
}

.slick-next:before, .slick-prev:before {
    color: #ffeb00;
    border: #0f142e;
    font-size: 45px;
   
}

.gallerySlider .slick-track > div > div{
  padding: 0 10px;
}

.slick-prev{
  left: 12px;
  z-index: 1;
  width: 50px;
  height: 50px;
  top: unset;
  bottom:0
}

.slick-next{
  right: 13px;
  z-index: 1;
  width: 50px;
  height: 50px;
  top: unset;
  bottom:0
}

@media only screen and (min-width: 5120px){
    .container{
        max-width: 100%;
    }
}

@media only screen and (min-width: 1023px) and (max-width: 1025px) {
    .galleryDiv img{
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 768px) {
    .galleryDiv img{
        width: 100%;
        height: auto;

  }
}
`

