import React from 'react';
import Header from '../../components/Header/Header';
import logo from "../../Media/Simon_Signature.png";
import ReactPlayer from 'react-player';
import './Videos.scss';

export default function Videos() {

    return (
        <div className="videoPage">
            <Header bg={"navbar-light"} logo={logo} />

            <div className="container for-bg main">
                <div className="videoPlayer">
                    <div className="videoPlayer col-xs-12">
                        <ReactPlayer className="for-height"
                            url="https://vimeo.com/641319014/7462193dec"
                            playing={false}
                            width="100%"
                            controls={true}
                            loop={true}
                        />
                    </div>
                </div>
            </div>

            <div className="container for-bg">

                <div className="videoPlayer">
                    <div className="col-xs-12">
                        <ReactPlayer className="for-height"
                            url="https://vimeo.com/589541620/870346bd23"
                            playing={false}
                            width="100%"
                            controls={true}
                            loop={true}
                        />
                    </div>
                </div>

                <div className="videoPlayer">
                    <div className="col-xs-12">
                        <ReactPlayer className="for-height"
                            url="https://vimeo.com/589541385/b5771bcc8b"
                            playing={false}
                            width="100%"
                            controls={true}
                            loop={true}
                        />
                    </div>
                </div>

                <div className="videoPlayer">
                    <div className="col-xs-12">
                        <ReactPlayer className="for-height"
                            url="https://vimeo.com/588523443/d21d2b9ab2"
                            playing={false}
                            width="100%"
                            controls={true}
                            loop={true}
                        />
                    </div>
                </div>

                <div className="videoPlayer">
                    <div className="col-xs-12">
                        <ReactPlayer className="for-height"
                            url="https://vimeo.com/589541281/97f0c73fe9"
                            playing={false}
                            width="100%"
                            controls={true}
                            loop={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}